.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .table-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    max-width: 280px;
    flex: 1;
  }
  .table-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  

 
 
  @media only screen and (max-width: 450px) {

    .table-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .table-container {
      width: 100%;
    }
  }
  