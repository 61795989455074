.dot {
    height: 12px;
    width: 12px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .card {
    width: 33.33%;
   
  }
  
  @media (max-width: 767px) {
    .card {
      width: 90%;
    }
  }
  