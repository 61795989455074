@font-face {
  font-family: 'Changa One';
  src: url('../../fonts/ChangaOne-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Anonymous Pro';
  src: url('../../fonts/AnonymousPro-Regular.ttf') format('truetype');
}

.homepage-feature {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 50px auto;
    max-width: 1200px;
  }

  .p-homepage {
    font-family: 'Anonymous Pro', monospace;
    animation: fade-in 0.5s ease-out;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 600px) {
    .p-homepage {
        padding-left: 10px; 
        padding-right: 10px;
    }
}

  .title-carousel {
    font-family: 'Changa One', cursive;
    line-height: 1.5;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    color: white;
    
  }
  .p-carousel {
    font-family: 'Anonymous Pro', monospace;
    font-size: 12px;
    line-height: 1.5;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    color: white;
  }
  .h1-homepage {
    font-family: 'Changa One', cursive;
    animation: fade-in 0.5s ease-out;
    font-size: 36px;
    margin: 20px 0;
    font-weight: bold;
  }

  .tweet-container{
    margin-bottom: 40px;
  }

  .logo {
    display: block;
    margin: 20px auto;
    width: 200px;
    height: 200px;
    background-image: url("https://i.postimg.cc/FzbX7fwz/basketball.webp");
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }

  .logoEsports{
    display: block;
    margin: 20px auto;
    width: 200px;
    height: 200px;
    background-image: url("https://i.postimg.cc/bY3czRjZ/esports.png");
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }

  .carousel-item img {
    width: 600px;
    height: 400px;
    object-fit: cover;
  }

  .carousel-item-esports {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .carousel-caption-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 20px;
    padding: 5px 10px;
  }


  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }