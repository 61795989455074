.alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .containerProva {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  