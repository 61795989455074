:root{
    --color-dark: #2C3131;
    --color-light: #F3F3F2;
}

#main{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
}

.board {
    text-align: center;
}

.board .leaderboard{
    margin-bottom: 1em;
}

.board .duration{
    display: flex;
    justify-content: center;
    gap: 1em;
}

.board .duration > button{
    border: 1px solid var(--color-light);
    padding: .5em 2em;
    border-radius: 50px;
    background-color: transparent;
    cursor: pointer;
}

.board .duration > button:hover{
    background-color: var(--color-dark);
    color: var(--color-light);
}

.active{
    background-color: var(--color-dark);
    color: var(--color-light);
}

#profile{
    /* display: flex; */

    margin-top: 3em;
}

#profile .flex{
    display: flex;
    justify-content: space-between;
    gap: 1em;
    text-align: left;
    margin-bottom: 2em;
}

#profile .flex .item{
    display: flex;
    align-items: center;
}

#profile .flex img{
    width: 20%;
    border-radius: 50%;
}

#profile .flex .info{
   padding: 1em;
}

.text-dark{
    color: var(--color-dark);
}

.bronze {
    background-color: #e49846;
  }
.gold {
    background-color: #e4c946;
  }
.silver {
    background-color: #c0c0c0;
  }

  .select-leaderboard {
    background-color: #ffffff;
    color: #000000;
    font-size: 30px;
    border-radius: 5px;
    padding: 10px 20px;
  }

  .gold-point{
    height: 12px;
    width: 12px;
    background-color: #e4c946;
    border-radius: 50%;
  }
  .silver-point{
    height: 12px;
    width: 12px;
    background-color: #c0c0c0;
    border-radius: 50%;
  }
  .bronze-point{
    height: 12px;
    width: 12px;
    background-color: #e49846;
    border-radius: 50%;
  }