.bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    
    
  }

  .no-underline {
    text-decoration: none;
  }

  @media only screen and (max-width: 768px) {

    .no-underline {
      text-decoration: none;
      font-size: 10px; 
    }
  }